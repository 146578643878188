import React from 'react';
import './Recipes.css'
import Discover from '../Discover/Discover';
import SeasonFav from '../SeasonFav/SeasonFav';
import Vegan from '../vegan/Vegan';
import Cakes from '../cakes/Cakes';
import Dessert from '../dessert/Dessert';
import Baking from '../baking/Baking';

const Recipes = () => {
  return (
    <div className='recipes'>
      <header className='recipes-header'>
        <h1>Recipes</h1>
      </header>
      <div className='recipes-subcategories'>
        <ul>
          <li>
            <Discover />
          </li>
          <li>
            <SeasonFav />
          </li>
          <li><Vegan /></li>
          <li><Cakes /></li>
          <li><Dessert /></li>
          <li><Baking /></li>
        </ul>
      </div>
    </div>
  );
}

export default Recipes;
