import React, { useEffect, useRef, useState } from 'react';
import './Baking.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';


const Baking = () => {
  const [isVisible, setIsVisible] = useState(false);
  const bakingRef = useRef(null);
  const { setFood } = useFoodProvider();

  const bakedGoods = [
    {
      id: 1,
      name: 'Banana Bread',
      description: 'Moist and delicious banana bread with a hint of cinnamon.',
      img: 'https://images.unsplash.com/photo-1678526793221-df7e55a4e65f?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      id: 2,
      name: 'Blueberry Muffins',
      description: 'Soft muffins packed with fresh blueberries.',
      img: 'https://images.unsplash.com/photo-1613119719865-d8b74141ddc9?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      id: 3,
      name: 'Chocolate Chip Cookies',
      description: 'Classic chocolate chip cookies, crispy on the outside and chewy on the inside.',
      img: 'https://images.unsplash.com/photo-1464195244916-405fa0a82545?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      id: 4,
      name: 'Cinnamon Rolls',
      description: 'Sweet and sticky cinnamon rolls topped with cream cheese icing.',
      img: 'https://images.unsplash.com/photo-1650626106569-df2f58f74bbe?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (bakedGood) => {
    setFood(bakedGood);
    navigate(`/recipe/baking/${bakedGood.name}`);
  };

  useEffect(() => {
    const currentRef = bakingRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const location = useLocation();
  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '610px' : 'auto',
    minHeight: location.pathname !== '/recipe' ? '620px' : 'auto'
  };


  return (
    <section
      id="Baking"
      ref={bakingRef}
      className={`baking food-list ${isVisible ? 'visible' : ''}`}
      style={sectionStyle}
    >
      <h2>Baking Specials</h2>
      <p>Delight in our freshly baked goods.</p>
      <div className="food-items">
        {bakedGoods.map(bakedGood => (
          <div key={bakedGood.id} className="food-item" onClick={() => handleNavigation(bakedGood)}>
            <div className="food-item-img">
              <img src={bakedGood.img} alt={bakedGood.name} className="food-image" />
            </div>
            <div className="food-item-info">
              <h3>{bakedGood.name}</h3>
              <p>{bakedGood.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Baking;
