import React, { useEffect, useRef, useState } from 'react';
import './Crockpot.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';

const Crockpot = () => {
  const [isVisible, setIsVisible] = useState(false);
  const crockpotRef = useRef(null);
  const { setFood } = useFoodProvider();

  const crockpotFoods = [
    { 
      id: 1, 
      name: 'Slow Cooker Chicken Curry', 
      description: 'A flavorful curry made with tender chicken and a rich sauce.', 
      img: 'https://www.cubesnjuliennes.com/wp-content/uploads/2020/08/Slow-Cooker-Chicken-Curry-Recipe.jpg' 
    },
    { 
      id: 2, 
      name: 'Honey Garlic Chicken', 
      description: 'Sweet and savory chicken cooked in a honey garlic sauce.', 
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd8yKNe2c7wVMQ4Ivr96PotQCUbbtizmut0A&s' 
    },
    { 
      id: 3, 
      name: 'Crockpot Chicken Enchiladas', 
      description: 'Tender chicken with a flavorful enchilada sauce, cooked to perfection.', 
      img: 'https://www.createkidsclub.com/wp-content/uploads/2021/08/chicken-enchilada-crockpot-meal-08-500x500.jpg' 
    },
    { 
      id: 4, 
      name: 'Creamy Tuscan Chicken', 
      description: 'Chicken slow-cooked in a creamy Tuscan sauce with sun-dried tomatoes and spinach.', 
      img: 'https://www.spoonforkbacon.com/wp-content/uploads/2021/02/tuscan-chicken-rc.jpg' 
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (food) => {
    setFood(food);
    navigate(`/crockpot_chicken_recipes/${food.name}`);
  };

  useEffect(() => {
    const currentRef = crockpotRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const location = useLocation();


  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '603px' : 'auto'
  };

  return (
    <section id='crockpotchickenrecipes' ref={crockpotRef} className={`crockpots food-list ${isVisible ? 'visible' : ''}`} style={sectionStyle}>
      <h2>Crockpot Chicken Recipes</h2>
      <p>Discover delicious chicken recipes made in the crockpot, perfect for easy meals.</p>
      <div className='food-items'>
        {crockpotFoods.map(food => (
          <div key={food.id} className='food-item' onClick={() => handleNavigation(food)}>
            <div className="food-item-img">
              <img src={food.img} alt={food.name} className='food-image' />
            </div>
            <div className="food-item-info">
              <h3>{food.name}</h3>
              <p>{food.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Crockpot;
