import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about-container">
      <h2>About Me</h2>
      
      <div className="about-section">
        <h3>Who Am I?</h3>
        <p>Hello! I’m a passionate home cook and food blogger. My love for cooking started at a young age, and over the years, I have honed my skills in creating delicious and nutritious meals. I believe that food is not just about sustenance but also about bringing joy and comfort to those we care about.</p>
      </div>

      <div className="about-section">
        <h3>What I Will Provide</h3>
        <p>On this blog, you’ll find a variety of recipes ranging from quick weeknight dinners to elaborate weekend feasts. I focus on using fresh, seasonal ingredients to create meals that are not only tasty but also healthy. Whether you're a beginner or an experienced cook, you'll find recipes here that suit your skill level and time constraints.</p>
      </div>
    </section>
  );
}

export default About;
