import React, { useEffect, useRef, useState } from 'react';
import './Rotisserie.css'; 
import { useNavigate,useLocation } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';

const Rotisserie = () => {
  const [isVisible, setIsVisible] = useState(false);
  const rotisserieRef = useRef(null);
  const { setFood } = useFoodProvider();

  const rotisserieFoods = [
    { 
      id: 1, 
      name: 'Classic Roast Chicken', 
      description: 'Tender and juicy chicken roasted to perfection.', 
      img: 'https://images.unsplash.com/photo-1584949602334-4e99f98286a9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' 
    },
    { 
      id: 2, 
      name: 'Herb-Crusted Pork Roast', 
      description: 'A flavorful pork roast with a crispy herb crust.', 
      img: 'https://images.unsplash.com/photo-1687877723436-061cc6e37fe8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' 
    },
    { 
      id: 3, 
      name: 'Garlic Herb Roasted Turkey', 
      description: 'Juicy turkey pieces marinated with garlic, herbs, and olive oil, then roasted to perfection.', 
      img: 'https://images.unsplash.com/photo-1555939594-58d7cb561ad1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Um9hc3RlZCUyMFR1cmtleSUyMCUyMGZvb2R8ZW58MHwxfDB8fHwy' 
    },
    { 
      id: 4, 
      name: 'Lemon Garlic Lamb', 
      description: 'Succulent lamb seasoned with lemon, garlic, and rosemary.', 
      img: 'https://simply-delicious-food.com/wp-content/uploads/2018/09/easy-lemon-garlic-lamb-chops-3.jpg' 
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (food) => {
    setFood(food);
    navigate(`/leftover_recipe/rotisserie_chicken/${food.name}`);
  };

  useEffect(() => {
    const currentRef = rotisserieRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const location = useLocation();


  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '610px' : 'auto',
    minHeight: location.pathname !== '/leftover_recipe' ? '603px' : 'auto'
  };

  return (
    <section
      id='Rotisserie'
      ref={rotisserieRef}
      className={`rotisserie food-list ${isVisible ? 'visible' : ''}`}
      style={sectionStyle}
    >
      <h2>Rotisserie Specials</h2>
      <p>Enjoy our selection of perfectly roasted meats and vegetables.</p>
      <div className='food-items'>
        {rotisserieFoods.map(food => (
          <div key={food.id} className='food-item' onClick={() => handleNavigation(food)}>
            <div className="food-item-img">
              <img src={food.img} alt={food.name} className='food-image' />
            </div>
            <div className="food-item-info">
              <h3>{food.name}</h3>
              <p>{food.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Rotisserie;
