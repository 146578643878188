import React, { useState, useEffect, useContext, createContext } from 'react';

const FoodContext = createContext();

export const FoodProvider = ({ children }) => {
   
    const getDataFromStorage = (key, defaultValue) => {
        try {
          const item = localStorage.getItem(key);
          return item ? JSON.parse(item) : defaultValue;
        } catch (error) {
          console.error(`Error parsing ${key} from localStorage`, error);
          return defaultValue;
        }
      };

      const [food, setFood] = useState(() => getDataFromStorage('food', ''));
      const [search, setSearch] = useState('');
  
      
      useEffect(() => {
        localStorage.setItem('food',JSON.stringify(food));
      }, [food]);

    return (
        <FoodContext.Provider value={{ food, setFood, search, setSearch }}>
            {children}
        </FoodContext.Provider>
    );
};

export const useFoodProvider = () => useContext(FoodContext);
