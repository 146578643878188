import React from 'react';
import './Landing.css';
import Discover from '../Discover/Discover';
import SeasonFav from '../SeasonFav/SeasonFav';
import Community from '../community/Community';
import Vegan from '../vegan/Vegan';
import Cakes from '../cakes/Cakes';
import Rostisserie from '../rotisserie/Rotisserie';
import Ham from '../Ham/Ham';
import Crockpot from '../crockpot/Crockpot';
import Rotisserie from '../rotisserie/Rotisserie';
import Dessert from '../dessert/Dessert';
import Baking from '../baking/Baking';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {

 const navigate = useNavigate();

  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1>Welcome to TasteFusion</h1>
        <p>Your go-to place for delicious recipes!</p>
        <a href className="cta-button" style={{cursor:"pointer"}} onClick={()=>navigate('/recipe')}>Explore Recipes</a>
      </header>
      <section className="landing-features">
        <div className="feature discoversec">
          <Discover />
        </div>
        <div className="feature seasonfavsec">
          <SeasonFav />
        </div>
        <div className="feature vegan">
          <Vegan />
        </div>
        <div className="feature cakes">
        <Cakes />
        </div>
        <div className="feature dessert">
         <Dessert />
       </div>
       <div className="feature baking">
         <Baking />
       </div>
        <div className="feature Rostisserie">
         <Rotisserie />
        </div>
        <div className="feature ">
         <Ham />
        </div>
        <div className="feature crockpot">
         <Crockpot />
        </div>
       
        
        
        
        <div className="feature">
          <Community />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
