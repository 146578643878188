import React, { useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/logo.png'

const Header = () => {
  const [navActive, setNavActive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [leftoverDropdownOpen, setLeftoverDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    setNavActive(false);
    setDropdownOpen(false);
    setSecondDropdownOpen(false);
    setLeftoverDropdownOpen(false);
  };

  const handleCategoryClick = (value) =>{
    navigate(value);
  }

  return (
    <header className="header">
      <div className="ham" onClick={() => setNavActive(true)}>
        <div className="hamline"></div>
        <div className="hamline"></div>
        <div className="hamline"></div>
      </div>
      <div className={`cross ${navActive ? 'crossActive' : ''}`} onClick={() => setNavActive(false)}>X</div>
      <div className="logo">
        <h1><a href="/"><img src={logo} alt="" /></a></h1>
      </div>
      <div className={`backblack ${navActive ? 'navactive' : ''}`}></div>
      <nav className={`nav ${navActive ? 'navactive' : ''}`}>
        <ul>
          <li><a href onClick={() => handleNavigation('/')}>Home</a></li>
          <li className="dropdown">
            <div className="dropbutn" onClick={() => handleCategoryClick('/recipe')}>Recipes</div>
            <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
              <p onClick={() => handleNavigation('/recipe/trending')}>Trending Recipe</p>
              <p onClick={() => handleNavigation('/recipe/season_fav')}>Seasonal Favorites</p>
              <p onClick={() => handleNavigation('/recipe/vegan')}>Vegan</p>
              <p onClick={() => handleNavigation('/recipe/cakes')}>Cakes</p>
              <p onClick={() => handleNavigation('/recipe/desert')}>Desert Recipe</p>
              <p onClick={() => handleNavigation('/recipe/baking')}>Baking Recipe</p>
            </div>
          </li>
          <li className="dropdown">
            <div className="dropbutn" onClick={() => handleCategoryClick('/leftover_recipe')}>Leftover Recipes</div>
            <div className={`dropdown-content ${leftoverDropdownOpen ? 'show' : ''}`}>
            <p onClick={() => handleNavigation('/leftover_recipe/rotisserie_chicken')}>Rotisserie Specials</p>
              <p onClick={() => handleNavigation('/leftover_recipe/ham_specials')}>Ham Specials</p>
            </div>
          </li>
           <li><p onClick={() => handleNavigation('/crockpot_chicken_recipes')}>Crockpot Chicken Recipes</p></li>
          <li><a href onClick={() => handleNavigation('/about')}>About</a></li>
          <li><a href onClick={() => handleNavigation('/contact')}>Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
