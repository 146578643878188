import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css'

const Footer = () => {

    const navigate = useNavigate();

  return (
        <footer className="footer">
        <p>&copy; 2024 TasteFusion.life. All rights reserved.</p>
        <p onClick={()=>navigate('/termsandcondition')}>Terms & Condition</p>
        <p onClick={()=>navigate('/privacypolicy')}>Privacy Policy</p>
      </footer>
  )
}

export default Footer