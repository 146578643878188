import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-container">
      <h2>Privacy Policy</h2>
      
      <div className="privacy-policy-section">
        <h3>Introduction</h3>
        <p>Welcome to our Privacy Policy. Your privacy is critically important to us. This policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>Information We Collect</h3>
        <p>We may collect information about you in a variety of ways. The information we may collect includes personal data, such as your name, email address, and contact details, as well as non-personal data, such as your browsing history and usage data.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect from you to provide and manage our services, communicate with you, improve our website, and for other purposes as described in this policy.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>Disclosure of Your Information</h3>
        <p>We may disclose your information to third parties when required by law or in the good faith belief that such action is necessary to comply with legal obligations, protect the rights of others, or ensure the safety of our services.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>Security of Your Information</h3>
        <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no security measures are completely foolproof, and we cannot guarantee the absolute security of your information.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this policy periodically for any changes.</p>
      </div>

      <div className="privacy-policy-section">
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at contact@tastefusion.com ..</p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
