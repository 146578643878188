import React from 'react';
import './Recipe.css';
import { useParams } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';

const Recipe = () => {
  const { recipeName } = useParams();
  const { food } = useFoodProvider();

  if (!food || food.name !== recipeName) {
    return <p>Recipe not found</p>;
  }

  return (
    <div className="recipe-container">
      <div className="recipe-content">
        <img src={food.img} alt={food.name} className="recipe-image" />
        <div className="recipe-details">
          <h2>{food.name}</h2>
          <p>{food.description}</p>
          <h3>Steps to Prepare</h3>
          <ul>
            <li>Step 1: Gather all ingredients.</li>
            <li>Step 2: Prepare the {food.name} according to the recipe.</li>
            <li>Step 3: Cook or bake as required.</li>
            <li>Step 4: Serve and enjoy!</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Recipe;
