import React from 'react';
import Container from './components/container/Container';
import './assets/style.css'
import { FoodProvider } from './context/FoodContext';

function App() {
  return (
    <div>
      <FoodProvider>
      <Container />
      </FoodProvider>
    </div>
  );
}

export default App;
