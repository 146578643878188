import React, { useEffect, useRef, useState } from 'react';
import './Dessert.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';


const Dessert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const dessertRef = useRef(null);
  const { setFood } = useFoodProvider();

  const desserts = [
    {
      id: 1,
      name: 'Chocolate Lava Cake',
      description: 'A warm, molten chocolate cake with a rich center.',
      img: 'https://images.pexels.com/photos/291528/pexels-photo-291528.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
      id: 2,
      name: 'Cheesecake',
      description: 'Creamy cheesecake with a graham cracker crust.',
      img: 'https://images.unsplash.com/photo-1724398931058-5470ea794a61?q=80&w=1830&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      id: 3,
      name: 'Tiramisu',
      description: 'A classic Italian dessert with layers of coffee-soaked ladyfingers and mascarpone cream.',
      img: 'https://images.unsplash.com/photo-1712262582593-e13609aaf12b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 4,
        name: 'French Macarons',
        description: 'Delicate and colorful meringue-based cookies with a variety of fillings.',
        img: 'https://images.unsplash.com/photo-1724441980088-dd7080cd5d32?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
      },
  ];

  const navigate = useNavigate();

  const handleNavigation = (dessert) => {
    setFood(dessert);
    navigate(`/recipe/dessert/${dessert.name}`);
  };

  useEffect(() => {
    const currentRef = dessertRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const location = useLocation();
  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '400px' : 'auto',
    minHeight: location.pathname === '/recipe' ? 'auto' : '623px'
  };


  return (
    <section
      id="Dessert"
      ref={dessertRef}
      className={`desserts food-list ${isVisible ? 'visible' : ''}` }
      style={sectionStyle}
    >
      <h2>Dessert Specials</h2>
      <p>Indulge in our selection of sweet treats.</p>
      <div className="food-items">
        {desserts.map(dessert => (
          <div key={dessert.id} className="food-item" onClick={() => handleNavigation(dessert)}>
            <div className="food-item-img">
              <img src={dessert.img} alt={dessert.name} className="food-image" />
            </div>
            <div className="food-item-info">
              <h3>{dessert.name}</h3>
              <p>{dessert.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Dessert;
