import React from 'react'
import './Home.css';
import LandingPage from '../Landing/Landing';

const Home = () => {
  return (
    <div className='home'>
      <LandingPage />
    </div>
  )
}

export default Home