import React from 'react';
import './Discover.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFoodProvider } from '../../../context/FoodContext';

const Discover = () => {
  const { setFood } = useFoodProvider();
  const americanFoods = [
    { id: 1, name: 'Cheeseburger', description: 'A classic American cheeseburger with a juicy beef patty, melted cheese, lettuce, tomato, and pickles.', img: "https://images.unsplash.com/photo-1498579485796-98be3abc076e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 2, name: 'Buffalo Wings', description: 'Spicy chicken wings coated in a tangy buffalo sauce, served with celery and ranch dipping sauce.', img: "https://images.unsplash.com/photo-1631897788978-da06ec45adcb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 3, name: 'Apple Pie', description: 'A traditional American dessert made with spiced apples and a flaky pie crust.', img: "https://plus.unsplash.com/premium_photo-1666353535340-4819e944fd75?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (value) => {
    setFood(value);
    navigate(`/recipe/trending/${value.name}`);
  }

  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '620px' : 'auto',
    minHeight: location.pathname !== '/recipe' ? '603px' : 'auto'
  };

  return (
    <section id='trending' className="discover food-list" style={sectionStyle}>
      <h2>Trending Recipes</h2>
      <p>Find recipes for every trending occasion and taste.</p>
      <div className="food-items">
        {americanFoods.map(food => (
          <div key={food.id} className="food-item" onClick={() => handleNavigation(food)}>
            <img src={food.img} alt={food.name} className="food-image" />
            <h3>{food.name}</h3>
            <p>{food.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Discover;
