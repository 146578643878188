import React from 'react';
import './Leftover.css'
import Rotisserie from '../rotisserie/Rotisserie';
import Ham from '../Ham/Ham';

const Leftover = () => {
  return (
    <div className='leftover'>
      <header className='leftover-header'>
        <h1>Leftover Recipes</h1>
      </header>
      <div className='leftover-subcategories'>
        <ul>
          <li>
            <Rotisserie />
          </li>
          <li><Ham /></li>
        </ul>
      </div>
    </div>
  );
}

export default Leftover;
