import React, { useEffect } from 'react';
import Home from '../view/home/Home';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from '../view/Header/Header';
import About from '../view/about/About';
import Terms from '../view/Terms/Terms';
import Footer from '../view/Footer/Footer';
import Contact from '../view/contact/Contact';
import PrivacyPolicy from '../view/Privacypolicy/PrivacyPolicy';
import Recipe from '../view/recipe/Recipe';
import Discover from '../view/Discover/Discover';
import SeasonFav from '../view/SeasonFav/SeasonFav';
import Vegan from '../view/vegan/Vegan';
import Cakes from '../view/cakes/Cakes';
import Rotisserie from '../view/rotisserie/Rotisserie';
import Ham from '../view/Ham/Ham';
import Crockpot from '../view/crockpot/Crockpot';
import Recipes from '../view/Recipes/Recipes';
import Leftover from '../view/leftover/Leftover';
import Dessert from '../view/dessert/Dessert';
import Baking from '../view/baking/Baking';

const Container = () => {
  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  };

  return (
    <Router>
      <div className='container'>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/recipe" element={<Recipes />} />
          <Route exact path="/trending" element={<Discover />} />         
          <Route exact path="/season_fav" element={<SeasonFav />} />      
          <Route exact path="/vegan" element={<Vegan />} />               
          <Route exact path="/cakes" element={<Cakes />} />   
          <Route exact path="/desert" element={<Dessert />} /> 
          <Route exact path="/baking" element={<Baking />} /> 
          <Route exact path="/cakes" element={<Cakes />} /> 
          <Route exact path="/rotisserie_chicken" element={<Rotisserie />} />
          <Route exact path="/ham_specials" element={<Ham />} />
          <Route exact path="/recipe/trending" element={<Discover />} />
          <Route exact path="/recipe/season_fav" element={<SeasonFav />} />
          <Route exact path="/recipe/vegan" element={<Vegan />} />
          <Route exact path="/recipe/cakes" element={<Cakes />} />
          <Route exact path="/recipe/desert" element={<Dessert />} /> 
          <Route exact path="/recipe/baking" element={<Baking />} /> 
          <Route exact path="/leftover_recipe" element={<Leftover />} />
          <Route exact path="/leftover_recipe/rotisserie_chicken" element={<Rotisserie />} />
          <Route exact path="/leftover_recipe/ham_specials" element={<Ham />} />
          <Route exact path="/crockpot_chicken_recipes" element={<Crockpot />} />
          <Route path="/recipe/:subname/:recipeName" element={<Recipe />} />
          <Route path="/leftover_recipe/:subname/:recipeName" element={<Recipe />} />
          <Route path="/crockpot_chicken_recipes/:recipeName" element={<Recipe />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/termsandcondition" element={<Terms />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default Container;
