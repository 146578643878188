import React from 'react';
import './Community.css'; 

const Community = () => {
  return (
    <section className='join-community'>
      <h2>Join Our Community</h2>
      <p>Connect with other food enthusiasts and share tips.</p>
      <div className='join-community-form'>
        <input type='email' placeholder='Enter your email' />
        <button type='submit'>Subscribe</button>
      </div>
    </section>
  );
};

export default Community;
