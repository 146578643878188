import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <section className="contact-container">
      <h2>Contact Us</h2>
      
      <div className="contact-info">
        <div className="contact-details">
          <h3>Get in Touch</h3>
          <p><strong>Address:</strong> 888 West Rio Salado Parkway, Suite 2890, Tempe, AZ 85281</p>
          <p><strong>Phone:</strong> +1 888-681-4978</p>
          <p><strong>Email:</strong> contact@tastefusion.com</p>
        </div>

        <form className="contact-form">
          <h3>Send Us a Message</h3>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder='Enter Your Name' required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="number" id="phone" name="phone" placeholder='Enter Your Phone' required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder='Enter Your Email' required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="5" placeholder='Enter Your Message' required></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
